// console.log('sitewide javascript works!')

// Get browserwindow height for usage in CSS vars
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

// Booklet Toggler
// const bookletToggle = document.getElementById('switch');
// let bookletLabel = 'booklet';
// if (bookletToggle) {
//   bookletToggle.innerText = bookletLabel;
//   bookletToggle.addEventListener('click', function () {
//     document.body.classList.toggle('booklet');
//     bookletLabel === 'booklet' ? bookletLabel = 'site' : bookletLabel = 'booklet';
//     bookletToggle.innerText = bookletLabel;
//   })
// };
    
// Menu toggler
const menuToggler = document.querySelector('.menutoggler');
const mainMenu = document.querySelector('.mainmenu');
// toggle menu using icon
menuToggler.addEventListener('click', function (e) {
  e.stopPropagation();
  toggleMenu();
});
// dismiss menu clicking outside it
document.documentElement.addEventListener('click', function () {
  if (!menuToggler.classList.contains('collapsed')) {
    toggleMenu(menuToggler);
  };
});

function toggleMenu(e) {
  menuToggler.classList.toggle('collapsed');
  mainMenu.classList.toggle('hidden');
};

// Sticky scroll when the element is child of body
// onscroll can only be used on one instance per page
const stickyHeader = document.querySelector('.topbar');
const stickyDistance = stickyHeader.offsetTop;
window.onscroll = function (e) {
  if (window.scrollY > stickyDistance) {
    stickyHeader.classList.add('is-sticky');
  } else {
    stickyHeader.classList.remove('is-sticky');
  }
};

// // Sticky scroll when the element is child of parallax wrapper
// const scrollWrapper = document.querySelector('.siteheader');
// const stickyHeader = document.querySelector('.topbar');
// scrollWrapper.addEventListener('scroll', function (e) {
//   // console.log(scrollWrapper.scrollTop); // check
//   if (scrollWrapper.scrollTop > 0) {
//     stickyHeader.classList.add('is-sticky');
//   } else {
//     stickyHeader.classList.remove('is-sticky');
//   }
// });

// Modal
// https://jasonwatmore.com/post/2023/01/04/vanilla-js-css-modal-popup-dialog-tutorial-with-example
const openModalBtn = document.getElementById('openModal');
openModalBtn ? openModalBtn.addEventListener('click', openModal) : '';
const closeModalBtn = document.getElementById('closeModal');
closeModalBtn ? closeModalBtn.addEventListener('click', closeModal) : '';

function openModal() {
  // console.log('open modal')
  document.getElementById('signupModal').classList.add('is-open');
  document.body.classList.add('modal-active');
}

function closeModal() {
  // console.log('close modal')
  document.querySelector('.modal.is-open').classList.remove('is-open');
  document.body.classList.remove('modal-active');
}

// dismiss modal clicking outside it
document.documentElement.addEventListener('click', event => {
  if (event.target.classList.contains('modal')) {
    closeModal();
  }
});

// dismiss modal clicking ESC key
document.documentElement.addEventListener('keydown', event => {
  if (event.key === 'Escape') {
    closeModal();
  }
});

// Glider
// window.addEventListener('load', function () {
//   new Glider(document.querySelector('.glider'), {
//     slidesToShow: 1,
//     dots: '.dots',
//     draggable: true,
//     rewind: true
//   })
// })

// Swiper
// https://swiperjs.com/get-started
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
  // loop: true,
  speed: 1000,
  autoplay: {
    delay: 5000,
    stopOnLastSlide: true,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});

// Simple Parallax
// https://simpleparallax.com/
import simpleParallax from 'simple-parallax-js';

const images = document.querySelectorAll('.parallax img, .carousel img');
new simpleParallax(images, {
  // scale: 1.5
});

// Accordion
// https://www.aditus.io/patterns/accordion/
const accordionHeaders = document.querySelectorAll('data-accordion-header');
Array.prototype.forEach.call(accordionHeaders, accordionHeader => {
  let target = accordionHeader.parentElement.nextElementSibling;
  accordionHeader.onclick = () => {
    let expanded = accordionHeader.getAttribute('aria-expanded') === 'true' || false;
    accordionHeader.setAttribute('aria-expanded', !expanded);
    target.hidden = expanded;
  }
});